<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" @click="confirm('0')">保存草稿</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm('1')">提交</a-button>
        <a-button v-if="showType=='check'" @click="confirm('3')">审核不通过</a-button>
        <a-button v-if="showType=='check'" type="primary" @click="confirm('2')">审核通过</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formDatas" :rules="formRules" :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-tabs type="card" v-model="activeKey">
          <a-tab-pane key="1" tab="基础/设备信息"></a-tab-pane>
          <a-tab-pane key="2" tab="管理信息"></a-tab-pane>
          <a-tab-pane key="3" tab="设备安装信息"></a-tab-pane>
          <a-tab-pane key="4" tab="监测设备信息"></a-tab-pane>
        </a-tabs>
        <div v-show="activeKey == '1'">
          <!-- ==========================基础信息======================= -->
          <h3 style="font-weight: bold; color: #333;">基础信息</h3>
          <a-form-model-item label="电梯注册码" prop="liftnum">
            <a-input v-model.trim="formDatas.liftnum" />
          </a-form-model-item>
          <a-form-model-item label="救援码" prop="liftrescue">
            <a-input v-model.trim="formDatas.liftrescue" />
          </a-form-model-item>
          <a-form-model-item label="电梯追溯码" prop="traceabilitynum">
            <a-input v-model.trim="formDatas.traceabilitynum" />
          </a-form-model-item>
          <a-form-model-item label="设备代码" prop="devicecode">
            <a-input v-model.trim="formDatas.devicecode" />
          </a-form-model-item>
          <a-form-model-item label="注册登记机构" prop="adminuserdepid">
            <a-cascader v-if="formDatas.formalflag==0" :show-search="{ filter }" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='2'?deptOptions.filter(item => item.deptype=='2'):deptOptionsAll.filter(item => item.deptype=='2')" v-model="adminuserdepidCascaderSelected" :load-data="userInfo.usertype=='2'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择注册登记机构" :change-on-select="true" :allow-clear="false"></a-cascader>
            <a-input v-if="formDatas.formalflag==1" v-model="formDatas.registerdep"></a-input>
          </a-form-model-item>
          <a-form-model-item label="注册登记时间" prop="registertime">
            <a-date-picker v-model="formDatas.registertime" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="注册更新时间" prop="registeruptime">
            <a-date-picker v-model="registeruptimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="内部编号" prop="liftcode">
            <a-input v-model.trim="formDatas.liftcode" />
          </a-form-model-item>
          <a-form-model-item label="出厂编号" prop="factorynum">
            <a-input v-model.trim="formDatas.factorynum" />
          </a-form-model-item>
          <a-form-model-item label="厂车牌照编号" prop="factorylicense">
            <a-input v-model.trim="formDatas.factorylicense" />
          </a-form-model-item>
          <a-form-model-item label="操作人员" prop="operator">
            <a-input v-model.trim="formDatas.operator" />
          </a-form-model-item>
          <a-form-model-item label="区域" prop="areacode">
            <a-cascader :show-search="{ areaFilter }" :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="areaCascaderSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true" :allow-clear="false"></a-cascader>
          </a-form-model-item>
          <a-form-model-item label="安装地址" prop="adress">
            <a-input v-model.trim="formDatas.adress" />
          </a-form-model-item>
          <a-form-model-item label="坐标" prop="coordinatex">
            <a-input v-model.trim="formDatas.coordinatex" style="width: 30%" />
            <a-input v-model.trim="formDatas.coordinatey" style="width: 30%; margin-left: 5%" />
            <a-button type="primary" style="width: 30%; margin-left: 5%" @click="selectCoordinateVisible=true">选择</a-button>
          </a-form-model-item>
          <!-- <a-form-model-item label="监管单位" prop="adminuserdepid">
            <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='2'?deptOptions.filter(item => item.deptype=='2'):deptOptionsAll.filter(item => item.deptype=='2')" v-model="adminuserdepidCascaderSelected" :load-data="userInfo.usertype=='2'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择监管单位" :allow-clear="false"></a-cascader>
          </a-form-model-item> -->
          <a-form-model-item label="使用状态" prop="status">
            <a-radio-group v-model="formDatas.status">
              <a-radio :value="1">使用</a-radio>
              <a-radio :value="0">未使用</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="使用场所" prop="placeid">
            <a-select v-model="formDatas.placeid">
              <a-select-option v-for="(item, index) in placeidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="电梯重要等级" prop="level">
            <a-select v-model="formDatas.level">
              <a-select-option v-for="(item, index) in levelOptions" :key="index" :value="item.code">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="网络接入方式" prop="networktype">
            <a-select v-model="formDatas.networktype">
              <a-select-option v-for="(item, index) in networktypeOptions" :key="index" :value="item.code">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="formDatas.networktype==1" label="物联网卡号" prop="wlancardnum">
            <a-input v-model.trim="formDatas.wlancardnum" />
          </a-form-model-item>
          <a-form-model-item v-if="userInfo.usertype==0" label="数据类型" prop="status">
            <a-select v-model="formDatas.formalflag">
              <a-select-option :value="0">正式数据</a-select-option>
              <a-select-option :value="1">非正式数据</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- =======================设备信息======================= -->
          <h3 style="font-weight: bold; color: #333;">设备信息</h3>
          <a-form-model-item label="用户设备类别" prop="devicetypeid">
            <a-select v-model="formDatas.devicetypeid">
              <a-select-option v-for="(item, index) in devicetypeidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="用户设备型号" prop="devicemodel">
            <a-input v-model="formDatas.devicemodel"/>
          </a-form-model-item>
          <a-form-model-item label="电梯品牌" prop="brandid">
<!--            <a-input v-model="formDatas.brandname" read-only="read-only" @click="findBrand"></a-input>-->
            <a-select
                v-model="formDatas.brandid"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleChange"
                :dropdownMatchSelectWidth=false
                dropdownClassName="select-drop-down-platform"
            >
              <a-select-option v-for="(item, index) in brandidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="设备许可证" prop="devicelicense">
            <a-input v-model.trim="formDatas.devicelicense" />
          </a-form-model-item>
          <a-form-model-item label="许可证有效期" prop="devicelicstarttime">
            <a-range-picker v-model="devicelictimeRange"></a-range-picker>
          </a-form-model-item>
          <a-form-model-item label="许可证级别" prop="devicelicenselevel">
            <a-input v-model.trim="formDatas.devicelicenselevel" />
          </a-form-model-item>
          <a-form-model-item label="拖动方式" prop="dragtype">
            <a-select v-model="formDatas.dragtype">
              <a-select-option v-for="(item, index) in dragtypeOptions" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="最高楼层" prop="floornum">
            <a-input v-model.trim="formDatas.floornum" />
          </a-form-model-item>
          <a-form-model-item label="层数" prop="floorcount">
            <a-input v-model.trim="formDatas.floorcount" />
          </a-form-model-item>
          <a-form-model-item label="站数" prop="stepnum">
            <a-input v-model.trim="formDatas.stepnum" />
          </a-form-model-item>
          <a-form-model-item label="门数" prop="doornum">
            <a-input v-model.trim="formDatas.doornum" />
          </a-form-model-item>
          <a-form-model-item label="额定速度" prop="speed">
            <a-input v-model.trim="formDatas.speed" />
          </a-form-model-item>
          <a-form-model-item label="载重量(kg)" prop="weight">
            <a-input v-model.trim="formDatas.weight" />
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">设备使用标志和登记信息证件查看</h3>
          <a-form-model-item label="使用登记编号" prop="regesternum">
            <a-input v-model.trim="formDatas.regesternum" />
          </a-form-model-item>
          <a-form-model-item label="使用标志证" prop="userflagurl">
            <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" action="/upload" :file-list="userflagurlList" @change="userflagurlUploaded">
              <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="使用登记证" prop="userloginurl">
            <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" action="/upload" :file-list="userloginurlList" @change="userloginurlUploaded">
              <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
        </div>
        <div v-show="activeKey == '2'">
          <!-- ========================使用单位信息============== -->
          <h3 style="font-weight: bold; color: #333;">使用单位信息</h3>
          <a-form-model-item label="使用单位" prop="userdepid">
            <a-cascader v-if="formDatas.formalflag==0" :show-search="{ filter }" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='1'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype=='1'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            <a-input v-if="formDatas.formalflag==1" v-model="formDatas.userdepname"></a-input>
          </a-form-model-item>
          <a-form-model-item label="使用单位代码" prop="userdepid_depnum">
            <span>{{formDatas.userdepid_depnum}}</span>
          </a-form-model-item>
          <a-form-model-item label="营业执照代码" prop="userdepid_businessnum">
            <span>{{formDatas.userdepid_businessnum}}</span>
          </a-form-model-item>
          <a-form-model-item label="使用单位地址" prop="userdepid_address">
            <span>{{formDatas.userdepid_address}}</span>
          </a-form-model-item>
          <a-form-model-item label="物业公司所属品牌" prop="userdepid_liftpropertyname">
            <span>{{formDatas.userdepid_liftpropertyname}}</span>
          </a-form-model-item>
          <a-form-model-item label="邮政编码" prop="userdepid_mailcode">
            <span>{{formDatas.userdepid_mailcode}}</span>
          </a-form-model-item>
          <!-- <a-form-model-item label="物业所属品牌" prop="userdepid_liftpropertyid">
            <span>{{formDatas.userdepid_liftpropertyid}}</span>
          </a-form-model-item> -->
          <a-form-model-item label="安全管理员" prop="userdepid_safeperson">
            <span>{{formDatas.userdepid_safeperson}}</span>
          </a-form-model-item>
          <a-form-model-item label="安全管理员电话" prop="userdepid_safephone">
            <span>{{formDatas.userdepid_safephone}}</span>
          </a-form-model-item>
          <a-form-model-item label="负责人" prop="userdeppreson">
            <a-input v-model.trim="formDatas.userdeppreson" />
          </a-form-model-item>
          <a-form-model-item label="负责人电话" prop="userdeppersonphone">
            <a-input v-model.trim="formDatas.userdeppersonphone" :max-length="11" />
          </a-form-model-item>
          <a-form-model-item label="小区所属地产品牌" prop="estatemodelid">
            <a-select v-model="formDatas.estatemodelid">
              <a-select-option v-for="(item, index) in estatemodelidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="小区" prop="communityid">
            <a-input :value="communityidObj?communityidObj.value:''" style="width: 65%;" disabled />
            <a-button type="primary" style="width: 30%; margin-left: 5%" @click="selectcommunityidmodelVisible=true">选择</a-button>
          </a-form-model-item>
          <!-- =======================维保单位信息====================== -->
          <h3 style="font-weight: bold; color: #333;">维保单位信息</h3>
          <a-form-model-item label="维保单位" prop="maintenancedepid">
            <a-cascader v-if="formDatas.formalflag==0" :dropdownMatchSelectWidth=false :show-search="{ filter }" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='3'?deptOptions.filter(item => item.deptype=='3'):deptOptionsAll.filter(item => item.deptype=='3')" v-model="maintenancedepidCascaderSelected" :load-data="userInfo.usertype=='3'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择维保单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            <a-input v-if="formDatas.formalflag==1" v-model="formDatas.maintenancedep"></a-input>
          </a-form-model-item>
          <a-form-model-item label="维保单位代码" prop="maintenancedepid_depnum">
            <span>{{formDatas.maintenancedepid_depnum}}</span>
          </a-form-model-item>
          <a-form-model-item label="资质证书代码" prop="maintenancedepid_certificatenum">
            <span>{{formDatas.maintenancedepid_certificatenum}}</span>
          </a-form-model-item>
          <a-form-model-item label="营业执照代码" prop="maintenancedepid_businessnum">
            <span>{{formDatas.maintenancedepid_businessnum}}</span>
          </a-form-model-item>
          <a-form-model-item label="电梯安装许可证编码" prop="maintenancedepid_licensenum">
            <span>{{formDatas.maintenancedepid_licensenum}}</span>
          </a-form-model-item>
          <a-form-model-item label="单位地址" prop="maintenancedepid_address">
            <span>{{formDatas.maintenancedepid_address}}</span>
          </a-form-model-item>
          <a-form-model-item label="救援人员" prop="rescueperson">
            <span>{{formDatas.rescueperson}}</span>
          </a-form-model-item>
          <a-form-model-item label="救援人员电话" prop="rescuephone">
            <span>{{formDatas.rescuephone}}</span>
          </a-form-model-item>
          <a-form-model-item label="维保负责人1" prop="maintenancedepid_maintenanceperson1">
            <a-input v-model.trim="formDatas.maintenancedepid_maintenanceperson1"></a-input>
          </a-form-model-item>
          <a-form-model-item label="维保负责人1电话" prop="maintenancedepid_maintenancephone1">
            <a-input v-model.trim="formDatas.maintenancedepid_maintenancephone1"></a-input>
          </a-form-model-item>
          <a-form-model-item label="维保负责人2" prop="maintenancedepid_maintenanceperson2">
            <a-input v-model.trim="formDatas.maintenancedepid_maintenanceperson2"></a-input>
          </a-form-model-item>
          <a-form-model-item label="维保负责人2电话" prop="maintenancedepid_maintenancephone2">
            <a-input v-model.trim="formDatas.maintenancedepid_maintenancephone2"></a-input>
          </a-form-model-item>
          <a-form-model-item label="区域经理" prop="maintenancedepid_areamanager">
            <span>{{formDatas.maintenancedepid_areamanager}}</span>
          </a-form-model-item>
          <a-form-model-item label="区域经理电话" prop="maintenancedepid_areamanagerphone">
            <span>{{formDatas.maintenancedepid_areamanagerphone}}</span>
          </a-form-model-item>
          <a-form-model-item label="单位经理" prop="maintenancedepid_depmanager">
            <span>{{formDatas.maintenancedepid_depmanager}}</span>
          </a-form-model-item>
          <a-form-model-item label="单位经理电话" prop="maintenancedepid_depmanagerphone">
            <span>{{formDatas.maintenancedepid_depmanagerphone}}</span>
          </a-form-model-item>
          <a-form-model-item label="邮政编码" prop="maintenancedepid_mailcode">
            <span>{{formDatas.maintenancedepid_mailcode}}</span>
          </a-form-model-item>
          <a-form-model-item label="维保类型" prop="maintenancetype">
            <a-select v-model="formDatas.maintenancetype">
              <a-select-option v-for="(item, index) in maintenancetypeOptions" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="维保组" prop="maintenancegroupid">
            <a-select v-model="formDatas.maintenancegroupid">
              <a-select-option v-for="(item, index) in maintenancegroupidOptions" :key="index" :value="item.maintenancegroupid">{{item.maintenancegroupname}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="大修/改造日期" prop="bigrepairtime">
            <a-date-picker v-model="bigrepairtimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="大修周期(周)" prop="bigrepaircycle">
            <a-input v-model.trim="formDatas.bigrepaircycle" />
          </a-form-model-item>
          <!-- =======================产权单位信息======================= -->
          <h3 style="font-weight: bold; color: #333;">产权单位信息</h3>
          <a-form-model-item label="产权单位名称" prop="propertydep">
            <a-input v-model.trim="formDatas.propertydep" />
          </a-form-model-item>
          <a-form-model-item label="产权单位代码" prop="propertydepnum">
            <a-input v-model.trim="formDatas.propertydepnum" />
          </a-form-model-item>
          <a-form-model-item label="产权单位地址" prop="propertydepadr">
            <a-input v-model.trim="formDatas.propertydepadr" />
          </a-form-model-item>
          <a-form-model-item label="邮政编码" prop="propertydeppost">
            <a-input v-model.trim="formDatas.propertydeppost" />
          </a-form-model-item>
          <a-form-model-item label="产权单位法人" prop="propertydepperson">
            <a-input v-model.trim="formDatas.propertydepperson" />
          </a-form-model-item>
          <a-form-model-item label="产权单位负责人" prop="propertydepadmin">
            <a-input v-model.trim="formDatas.propertydepadmin" />
          </a-form-model-item>
          <a-form-model-item label="电话" prop="propertydepphone">
            <a-input v-model.trim="formDatas.propertydepphone" :max-length="12" />
          </a-form-model-item>
          <a-form-model-item label="安装日期" prop="installtime">
            <a-date-picker v-model="installtimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="使用日期" prop="usetime">
            <a-date-picker v-model="usetimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <!-- =====================检验信息==================== -->
          <h3 style="font-weight: bold; color: #333;">检验信息</h3>
          <a-form-model-item label="检验单位" prop="examinedepid">
            <a-cascader v-if="formDatas.formalflag==0" :show-search="{ filter }" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='4'?deptOptions.filter(item => item.deptype=='4'):deptOptionsAll.filter(item => item.deptype=='4')" v-model="examinedepidCascaderSelected" :load-data="userInfo.usertype=='4'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择检验单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            <a-input v-if="formDatas.formalflag==1" v-model="formDatas.examinedep"></a-input>
          </a-form-model-item>
          <a-form-model-item label="检验人员" prop="examineperson">
            <a-input v-model.trim="formDatas.examineperson" />
          </a-form-model-item>
          <a-form-model-item label="检验时间" prop="examinetime">
            <a-date-picker v-model="examinetimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="下次检验时间" prop="nextexaminetime">
            <a-date-picker v-model="nextexaminetimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="检验结论" prop="result">
            <a-select v-model="formDatas.result">
              <a-select-option value="0">合格</a-select-option>
              <a-select-option value="1">不合格</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="描述" prop="resultdesc">
            <a-input v-model.trim="formDatas.resultdesc" />
          </a-form-model-item>
          <a-form-model-item label="检验编号" prop="examinenum">
            <a-input v-model.trim="formDatas.examinenum" />
          </a-form-model-item>
          <!-- =====================检测信息==================== -->
          <h3 style="font-weight: bold; color: #333;">检测信息</h3>
          <a-form-model-item label="检测单位" prop="testdepid">
            <a-cascader v-if="formDatas.formalflag==0" :show-search="{ filter }" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='5'?deptOptions.filter(item => item.deptype=='5'):deptOptionsAll.filter(item => item.deptype=='5')" v-model="testdepidCascaderSelected" :load-data="userInfo.usertype=='5'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择检测单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            <a-input v-if="formDatas.formalflag==1" v-model="formDatas.testdep"></a-input>
          </a-form-model-item>
          <a-form-model-item label="检测人员" prop="testexamineperson">
            <a-input v-model.trim="formDatas.testexamineperson" />
          </a-form-model-item>
          <a-form-model-item label="检测时间" prop="testexaminetime">
            <a-date-picker v-model="testexaminetimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="下次检测时间" prop="testnextexaminetime">
            <a-date-picker v-model="testnextexaminetimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="检测结论" prop="testresult">
            <a-select v-model="formDatas.testresult">
              <a-select-option value="0">合格</a-select-option>
              <a-select-option value="1">不合格</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="描述" prop="testresultdesc">
            <a-input v-model.trim="formDatas.testresultdesc" />
          </a-form-model-item>
          <a-form-model-item label="检测编号" prop="testexaminenum">
            <a-input v-model.trim="formDatas.testexaminenum" />
          </a-form-model-item>
          <!-- <a-form-model-item label="下次检测时间">
            <span>{{testLists.nextexaminetime?moment(testLists.nextexaminetime, 'YYYYMMDD').format('YYYY-MM-DD'):''}}</span>
          </a-form-model-item>
          <a-form-model-item label="检测人员">
            <span>{{testLists.examineperson||''}}</span>
          </a-form-model-item>
          <a-form-model-item label="检测结论">
            <span>{{testLists.result=='0'?'合格':testLists.result=='1'?'不合格':''}}</span>
          </a-form-model-item> -->
          <!-- =====================应急处置单位==================== -->
          <h3 style="font-weight: bold; color: #333;">应急处置单位</h3>
          <a-form-model-item label="应急处置单位" prop="rescuedepid">
            <a-cascader v-if="formDatas.formalflag==0" :show-search="{ filter }" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='6'?deptOptions.filter(item => item.deptype=='6'):deptOptionsAll.filter(item => item.deptype=='6')" v-model="rescuedepidCascaderSelected" :load-data="userInfo.usertype=='6'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择应急处置单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            <a-input v-if="formDatas.formalflag==1" v-model="formDatas.rescuedep"></a-input>
          </a-form-model-item>
          <a-form-model-item label="二级救援站电话" prop="testexamineperson">
            <a-input v-model.trim="formDatas.pristationphone" />
          </a-form-model-item>
          <a-form-model-item label="驻站人一" prop="testexaminetime">
            <a-input v-model.trim="formDatas.stationperson1" />
          </a-form-model-item>
          <a-form-model-item label="驻站人一电话" prop="testnextexaminetime">
            <a-input v-model.trim="formDatas.stationphone1" />
          </a-form-model-item>
          <a-form-model-item label="驻站人二" prop="testexaminetime">
            <a-input v-model.trim="formDatas.stationperson2" />
          </a-form-model-item>
          <a-form-model-item label="驻站人二电话" prop="testnextexaminetime">
            <a-input v-model.trim="formDatas.stationphone2" />
          </a-form-model-item>
          <a-form-model-item label="二级救援站负责人" prop="testexaminetime">
            <a-input v-model.trim="formDatas.pristationperson" />
          </a-form-model-item>
          <a-form-model-item label="二级救援站负责人电话" prop="testnextexaminetime">
            <a-input v-model.trim="formDatas.pristationperphone" />
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">维保合同信息</h3>
          <a-form-model-item label="合同上传地址" prop="maintenancecontacturl">
            <!-- <a-input v-model.trim="formDatas.maintenancecontacturl" /> -->
            <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" action="/upload" :file-list="maintenancecontacturlList" @change="maintenancecontacturlUploaded">
              <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="合同有效期" prop="maintenancecontacttime">
            <a-date-picker v-model="maintenancecontacttimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">IP语音网关和可视通讯</h3>
          <a-form-model-item label="IP语音网关分机号" prop="ipnetnum">
            <a-input v-model.trim="formDatas.ipnetnum" />
          </a-form-model-item>
          <a-form-model-item label="视频对讲机号" prop="videonum">
            <a-input v-model.trim="formDatas.videonum" />
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">保险信息</h3>
          <a-form-model-item label="保险公司名称" prop="insurancedep">
            <a-input v-model.trim="formDatas.insurancedep" />
          </a-form-model-item>
          <a-form-model-item label="保险种类" prop="insurancetype">
            <a-input v-model.trim="formDatas.insurancetype" />
          </a-form-model-item>
          <a-form-model-item label="保险单号" prop="insurancenum">
            <a-input v-model.trim="formDatas.insurancenum" />
          </a-form-model-item>
          <a-form-model-item label="维修资金使用情况" prop="fundsused">
            <a-input v-model.trim="formDatas.fundsused" />
          </a-form-model-item>
          <a-form-model-item label="理赔电话" prop="insurancephone">
            <a-input v-model.trim="formDatas.insurancephone" :max-length="12" />
          </a-form-model-item>
          <a-form-model-item label="理赔有效期" prop="insuranceexpiretime">
            <a-date-picker v-model="insuranceexpiretimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
        </div>
        <div v-show="activeKey == '3'">
          <h3 style="font-weight: bold; color: #333;">设备安装信息</h3>
          <a-form-model-item label="安装单位" prop="installdep">
            <a-input v-model.trim="formDatas.installdep" />
          </a-form-model-item>
          <a-form-model-item label="安装单位编码" prop="installdepnum">
            <a-input v-model.trim="formDatas.installdepnum" />
          </a-form-model-item>
          <a-form-model-item label="安装证书编码" prop="installlicnum">
            <a-input v-model.trim="formDatas.installlicnum" />
          </a-form-model-item>
          <a-form-model-item label="安装负责人" prop="installadmin">
            <a-input v-model.trim="formDatas.installadmin" />
          </a-form-model-item>
          <a-form-model-item label="安装负责人电话" prop="installadminphone">
            <a-input v-model.trim="formDatas.installadminphone" />
          </a-form-model-item>
          <a-form-model-item label="安装施工单位" prop="constructiondep">
            <a-input v-model.trim="formDatas.constructiondep" />
          </a-form-model-item>
          <a-form-model-item label="施工日期" prop="constructiontime">
            <a-date-picker v-model="constructiontimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="土建验收单位" prop="acceptdep">
            <a-input v-model.trim="formDatas.acceptdep" />
          </a-form-model-item>
          <a-form-model-item label="审核单位" prop="checkunit">
            <a-input v-model.trim="formDatas.checkunit" />
          </a-form-model-item>
          <a-form-model-item label="审核日期" prop="checktime">
            <a-date-picker v-model="checktimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="验收报告编号" prop="checkreportnum">
            <a-input v-model.trim="formDatas.checkreportnum" />
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">设计制造信息</h3>
          <a-form-model-item label="设计单位" prop="designdep">
            <a-input v-model.trim="formDatas.designdep" />
          </a-form-model-item>
          <a-form-model-item label="设计单位编码" prop="designdepnum">
            <a-input v-model.trim="formDatas.designdepnum" />
          </a-form-model-item>
          <a-form-model-item label="设备制造商" prop="manufacturerid">
<!--            <a-input v-if="formDatas.formalflag==0" v-model="formDatas.manufacturername" read-only="read-only" @click="findManufacturer"></a-input>-->
<!--            <a-input v-if="formDatas.formalflag==1" v-model="formDatas.manufacturerdep" read-only="read-only"></a-input>-->
            <a-select
                v-if="formDatas.formalflag==0"
                v-model="formDatas.manufacturerid"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleChange"
                :dropdownMatchSelectWidth=false
                dropdownClassName="select-drop-down-platform"
            >
              <a-select-option v-for="(item, index) in manufactureridOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
            </a-select>
            <a-input v-if="formDatas.formalflag==1" v-model="formDatas.manufacturerdep" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="资格证" prop="manufacturerlic">
            <a-input v-model.trim="formDatas.manufacturerlic" />
          </a-form-model-item>
          <a-form-model-item label="生产许可证" prop="productlic">
            <a-input v-model.trim="formDatas.productlic" />
          </a-form-model-item>
          <a-form-model-item label="生产编号" prop="productnum">
            <a-input v-model.trim="formDatas.productnum" />
          </a-form-model-item>
          <a-form-model-item label="生产日期" prop="producttime">
            <a-date-picker v-model="producttimeMoment" style="width: 100%;"></a-date-picker>
          </a-form-model-item>
        </div>
        <div v-show="activeKey == '4'">
          <h3 style="font-weight: bold; color: #333;">监测设备信息</h3>
          <div style="text-align: right;">
            <a-button type="primary" @click="selectdeviceVisible=true">选择</a-button>
          </div>
          <a-table v-if="showDeviceTable" :columns="tableColumns" :data-source="deviceList" row-key="deviceinfoid" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 16px;" :pagination="false">
            <span slot="terminaltype" slot-scope="text">
              <span class="text">{{terminaltypesMap[text]||''}}</span>
            </span>
            <span slot="isonline" slot-scope="value">
              <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
            </span>
          </a-table>
        </div>
      </a-form-model>
    </a-modal>
    <select-coordinate :visible.sync="selectCoordinateVisible" :areacode="formDatas.areacode" @get-coordinate="getCoordinate"></select-coordinate>
    <select-devicemodel select-type="radio" :visible.sync="selectDevicemodelVisible" :default-selected="deviceModelObj?[deviceModelObj]:[]" @get-selected-rows="getDevicemodelSelectRows"></select-devicemodel>
    <select-device :visible.sync="selectdeviceVisible" :default-selected="deviceList" :formData="formDatas" @get-selected-rows="getDeviceList"></select-device>
    <check-modal :visible.sync="showApproveFailModal" :liftid="formDatas.liftid" @get-operation-result="approveResult"></check-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { getTimeRange } from 'U'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import SelectCoordinate from 'C/modals/SelectCoordinate.vue'
import SelectDevicemodel from 'C/modals/SelectDevicemodel.vue'
import SelectDevice from 'C/modals/SelectDevice.vue'
import SelectMonitor from 'C/modals/SelectMonitor.vue'
import CheckModal from 'V/facilitiesEquipment/elevator/elevatorCheck/checkModal.vue'
import {
  getLiftListByCondition,
  getLiftinfoById,
  addLiftinfo,
  modifyLiftinfo,
  approveLift,
  modifyUserdep,
} from 'A/jcgn'
import { getDictByDicType, getMentenacegroupListByCondition } from 'A/xtpz'
import {getDeviceparamValueList, getTerminaltypeListByCondition} from 'A/ai.js'
export default {
  mixins: [areaselect, deptselect],
  components: {
    SelectCoordinate,
    SelectDevicemodel,
    SelectDevice,
    SelectMonitor,
    CheckModal,
  },
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      size: 'default',
      brandselectVisible:false,
      ManufacturerselectVisible:false,
      selectCoordinateVisible: false,
      selectDevicemodelVisible: false,
      selectcommunityidmodelVisible: false,
      selectdeviceVisible: false,
      selectmonitorVisible: false,
      showApproveFailModal: false,
      modalBodyStyle: {
        height: '560px',
        overflowY: 'auto',
      },
      modalVisible: false,
      activeKey: '1',
      formDatas: {
        // 基础信息
        liftid: '',
        liftnum: '',
        liftrescue: '',
        traceabilitynum: '',
        devicecode: '',
        registerdep: '',
        registertime: '',
        registeruptime: '',
        liftcode: '',
        factorynum: '',
        factorylicense: '',
        operator: '',
        areacode: '',
        adress: '',
        adminuserdepid: '',
        coordinatex: '',
        coordinatey: '',
        status: 1,//0-未使用，1-使用
        placeid: '',
        level: '',
        // 设备信息
        devicetypeid: '',
        devicemodelid: '',
        devicemodel:'',
        brandid: '',
        brandname:'',
        devicelicense: '',
        devicelicstarttime: '',
        devicelicendtime: '',
        devicelicenselevel: '',
        dragtype: '',
        floornum: '',
        floorcount: '',
        stepnum: '',
        doornum: '',
        speed: '',
        weight: '',
        // 设备使用标志和登记信息证件查看
        regesternum: '',
        userflagurl: '',
        userloginurl: '',
        // 使用单位信息
        userdepid: '',
        userdepid_depnum: '',
        userdepid_businessnum: '',
        userdepid_address: '',
        userdepid_liftpropertyname: '',
        userdepid_mailcode: '',
        userdepid_safeperson: '',
        userdepid_safephone: '',
        userdepid_liftpropertyid: '',
        userdeppreson: '',
        userdeppersonphone: '',
        estatemodelid: '',
        communityid: '',
        // 产权单位信息
        propertydep: '',
        propertydepnum: '',
        propertydepadr: '',
        propertydeppost: '',
        propertydepperson: '',
        propertydepadmin: '',
        propertydepphone: '',
        installtime: '',
        usetime: '',
        // 设备维保信息
        maintenancedepid: '',
        maintenancedepname:'',
        maintenancedeptype:'',
        maintenancedeplevelid:'',
        maintenancedepid_depnum: '',//维保单位代码
        maintenancedepid_certificatenum: '',//资质证书代码
        maintenancedepid_businessnum: '',//营业执照代码
        maintenancedepid_licensenum: '',//电梯安装许可证编码
        maintenancedepid_address: '',//单位地址
        rescueperson: '',//救援人员
        rescuephone: '',//救援人员电话
        maintenancedepid_rescuepreson: '',//救援人员
        maintenancedepid_rescuephone: '',//救援人员电话
        maintenancedepid_maintenanceperson1: '',//维保负责人1
        maintenancedepid_maintenancephone1: '',//维保负责人1电话
        maintenancedepid_maintenanceperson2: '',//维保负责人2
        maintenancedepid_maintenancephone2: '',//维保负责人2电话
        maintenancedepid_areamanager: '',//区域经理
        maintenancedepid_areamanagerphone: '',//区域经理电话
        maintenancedepid_depmanager: '',//单位经理
        maintenancedepid_depmanagerphone: '',//单位经理电话
        maintenancedepid_mailcode: '',//单位经理电话
        maintenancetype: '',
        maintenancegroupid: '',
        //应急救援单位
        pristation:'',
        pristationphone:'',
        stationperson1:'',
        stationphone1:'',
        stationperson2:'',
        stationphone2:'',
        pristationperson:'',
        pristationperphone:'',
        // 检验信息
        examinedepid: '',
        examineperson: '',
        examinetime: '',
        nextexaminetime: '',
        result: '',
        resultdesc: '',
        examinenum: '',
        testdepid: '',
        testexamineperson: '',
        testexaminetime: '',
        testnextexaminetime: '',
        testresult: '',
        testresultdesc: '',
        testexaminenum: '',
        rescuedepid: '',
        bigrepairtime: '',
        bigrepaircycle: '',
        // 维保合同信息
        maintenancecontacturl: '',
        maintenancecontacttime: '',
        // IP语音网关和可视通讯
        ipnetnum: '',
        videonum: '',
        // 保险信息
        insurancedep: '',
        insurancenum: '',
        insurancephone: '',
        insuranceexpiretime: '',
        insurancetype:'',
        fundsused:'',
        // 设备安装信息
        installdep: '',
        installdepnum: '',
        installlicnum: '',
        installadminphone: '',
        installadmin: '',
        constructiondep: '',
        constructiontime: '',
        acceptdep: '',
        checkunit: '',
        checktime: '',
        checkreportnum: '',
        // 设计制造信息
        designdep: '',
        designdepnum: '',
        manufacturerid: '',
        manufacturername:'',
        manufacturerlic: '',
        productlic: '',
        productnum: '',
        producttime: '',
        // 审核状态
        checkstatus: '0',
        // 关联设备
        deviceid: '',
        linkiot:'',
        monitorid:'',

        networktype:'',
        wlancardnum:'',

        formalflag: 0,
      },
      registertimeMoment: null,
      registeruptimeMoment: null,
      examinetimeMoment: null,
      nextexaminetimeMoment: null,
      testexaminetimeMoment: null,
      testnextexaminetimeMoment: null,
      //这里面的数据属于必填项
      formRules: {
        liftnum: [{required: false, message: '请输入电梯注册码'}],
        liftrescue: [{required: false, message: '请输入救援码'}],
        // traceabilitynum: [{required: true, message: '请输入电梯追溯码'}],
        registerdep: [{required: true, message: '请输入注册登记机构'}],
        areacode: [{required: true, message: '请选择区域'}],
        adress: [{required: true, message: '请输入安装地址'}],
        adminuserdepid: [{required: true, message: '请选择注册登记机构'}],
        coordinatex: [{required: true, message: '请选择电梯位置坐标'}],
        registertime:[{required: true, message: '请选择注册登记时间'}],
        brandid:[{required: true, message: '请选择电梯品牌'}],
        // maintenancedepid_maintenanceperson1:[{required: true, message: '请填写维保负责人'}],
        // maintenancedepid_maintenance1:[{required: true, message: '请填写维保负责人电话'}],
        manufacturerid:[{required: true, message: '请选择设备制造商'}],
        placeid: [{required: true, message: '请选择使用场合'}],
        level: [{required: true, message: '请选择电梯重要等级'}],
        devicetypeid: [{required: true, message: '请选择设备类别'}],
        // regesternum: [{required: true, message: '请输入使用登记编号'}],
        userdepid: [{required: true, message: '请选择使用单位'}],
        // userdeppreson: [{required: true, message: '请输入负责人'}],
        userdeppersonphone: [
          {
            validator: (rule, value, callback) => {
              if(!value || /^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
        propertydepphone: [
          {
            validator: (rule, value, callback) => {
              if(!value||/^\d{7,12}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的电话号码')
              }
            }, trigger: 'blur'
          }
        ],
        // insurancephone: [
        //   {
        //     validator: (rule, value, callback) => {
        //       if(!value||/^\d{7,12}$/.test(value)) {
        //         callback();
        //       }else {
        //         callback('请输入正确的电话号码')
        //       }
        //     }, trigger: 'blur'
        //   }
        // ],
        installadminphone: [
          {
            validator: (rule, value, callback) => {
              if(!value || /^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
        //下列数据属于必填项
        // maintenancedepid: [{required: true, message: '请选择维保单位'}],
        // maintenancegroupid: [{required: true, message: '请选择维保组'}],
        // examinedepid: [{required: false, message: '请选择检验单位'}],
        // testdepid: [{required: false, message: '请选择检验单位'}],
        // rescuedepid: [{required: false, message: '请选择应急处置单位'}],
        // bigrepairtime: [{required: true, message: '请选择大修/改造日期'}],
      },
      placeidOptions: [],
      networktypeOptions:[
        {
          code: 0,
          name: '有线网络'
        },
        {
          code: 1,
          name: '物联网卡'
        },
      ],
      levelOptions: [
        {
          code: 0,
          name: '极高'
        },
        {
          code: 1,
          name: '高'
        },
        {
          code: 2,
          name: '一般'
        },
      ],
      devicetypeidOptions: [],
      deviceModelObj: null,
      communityidObj: null,
      brandidOptions: [],
      dataSource:[],
      devicelictimeRange: null,
      dragtypeOptions: [],
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      examinedepidCascaderSelected: [],
      rescuedepidCascaderSelected: [],
      testdepidCascaderSelected: [],
      registerdepCascaderSelected: [],
      adminuserdepidCascaderSelected: [],
      estatemodelidOptions: [],
      maintenancetypeOptions: [],
      maintenancegroupidOptions: [],
      installtimeMoment: null,
      usetimeMoment: null,
      bigrepairtimeMoment: null,
      insuranceexpiretimeMoment: null,
      constructiontimeMoment: null,
      checktimeMoment: null,
      producttimeMoment: null,
      maintenancecontacttimeMoment: null,
      manufactureridOptions: [],
      userflagurlList: [],
      userloginurlList: [],
      maintenancecontacturlList: [],
      examineLists: {},
      testLists: {},
      tableColumns: [
        {
          title: '设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: '注册代码',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
        },
        {
          title: '设备型号',
          dataIndex: 'terminaltype',
          key: 'terminaltype',
          ellipsis: true,
          scopedSlots: { customRender: 'terminaltype' }
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
      ],
      deviceList: null,
      showDeviceTable: false,
      terminaltypes: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadData']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
    terminaltypesMap() {
      let result = {};
      this.terminaltypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    },
    monitortypesMap() {
      let result = {};
      this.monitortypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetDatas();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    registertimeMoment(val) {
      if(val) {
        this.formDatas.registertime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('registertime')
      }else {
        this.formDatas.registertime = '';
      }
    },
    registeruptimeMoment(val) {
      if(val) {
        this.formDatas.registeruptime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('registeruptime')
      }else {
        this.formDatas.registeruptime = '';
      }
    },
    examinetimeMoment(val) {
      if(val) {
        this.formDatas.examinetime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('examinetime')
      }else {
        this.formDatas.examinetime = '';
      }
    },
    nextexaminetimeMoment(val) {
      if(val) {
        this.formDatas.nextexaminetime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('nextexaminetime')
      }else {
        this.formDatas.nextexaminetime = '';
      }
    },
    testexaminetimeMoment(val) {
      if(val) {
        this.formDatas.testexaminetime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('testexaminetime')
      }else {
        this.formDatas.testexaminetime = '';
      }
    },
    testnextexaminetimeMoment(val) {
      if(val) {
        this.formDatas.testnextexaminetime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('testnextexaminetime')
      }else {
        this.formDatas.testnextexaminetime = '';
      }
    },
    areaCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.areacode = val[val.length-1];
        this.$refs.modalForm.clearValidate('areacode')
      }else {
        this.formDatas.areacode = '';
      }
    },
    deviceModelObj(val) {
      if(val) {
        this.formDatas.devicemodelid = val.liftpropertyid;
      }else {
        this.formDatas.devicemodelid = ''
      }
    },
    deviceList(val) {
      if(val && val.length) {
        this.formDatas.deviceid = val.map(item => item.deviceinfoid).join('|');
        this.formDatas.maintenancedepid_rescuepreson = val.map(item => item.rescueperson).join('|');
        this.formDatas.maintenancedepid_rescuephone = val.map(item => item.rescuephone).join('|');
      }else {
        this.formDatas.deviceid = '';
      }
    },
    communityidObj(val) {
      if(val) {
        this.formDatas.communityid = val.liftpropertyid;
      }else {
        this.formDatas.communityid = ''
      }
    },
    devicelictimeRange(val) {
      let {start, end} = getTimeRange(val);
      this.formDatas.devicelicstarttime = start;
      this.formDatas.devicelicendtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.userdepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('userdepid')
        let deptInfo = this.getDeptInfoFormDeptOptionsByDeptid(this.formDatas.userdepid, this.userInfo.usertype!='1');
        if(deptInfo) {
          this.formDatas.userdepid_depnum = deptInfo.depnum || '';
          this.formDatas.userdepid_businessnum = deptInfo.businessnum || '';
          this.formDatas.userdepid_address = deptInfo.address || '';
          this.formDatas.userdepid_liftpropertyname = deptInfo.liftpropertyname || '';
          this.formDatas.userdepid_mailcode = deptInfo.mailcode || '';
          this.formDatas.userdepid_safeperson = deptInfo.safeperson || '';
          this.formDatas.userdepid_safephone = deptInfo.safephone || '';
          this.formDatas.userdepid_liftpropertyid = deptInfo.liftpropertyid || '';
        }
      }else {
        this.formDatas.userdepid = '';
        this.formDatas.userdepid_depnum = '';
        this.formDatas.userdepid_businessnum = '';
        this.formDatas.userdepid_address = '';
        this.formDatas.userdepid_liftpropertyname = '';
        this.formDatas.userdepid_mailcode = '';
        this.formDatas.userdepid_safeperson = '';
        this.formDatas.userdepid_safephone = '';
        this.formDatas.userdepid_liftpropertyid = '';
      }
    },
    registerdepCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.registerdep = val[val.length-1];
        this.$refs.modalForm.clearValidate('registerdep')
      }else {
        this.formDatas.registerdep = '';
      }
    },
    adminuserdepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.adminuserdepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('adminuserdepid')
      }else {
        this.formDatas.adminuserdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.maintenancedepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('maintenancedepid')
        this.initMaintenancegroupidOptions();
        let deptInfo = this.getDeptInfoFormDeptOptionsByDeptid(this.formDatas.maintenancedepid, this.userInfo.usertype!='3');
        if(deptInfo) {
          this.formDatas.maintenancedepid_depnum = deptInfo.depnum || '';
          this.formDatas.maintenancedeptype=deptInfo.deptype||'';
          this.formDatas.maintenancedeplevelid=deptInfo.levelid||'';
          this.formDatas.maintenancedepid_certificatenum = deptInfo.certificatenum || '';
          this.formDatas.maintenancedepid_businessnum = deptInfo.businessnum || '';
          this.formDatas.maintenancedepid_licensenum = deptInfo.licensenum || '';
          this.formDatas.maintenancedepid_address = deptInfo.address || '';
          this.formDatas.maintenancedepid_rescuepreson = deptInfo.rescuepreson || '';
          this.formDatas.maintenancedepid_rescuephone = deptInfo.rescuephone || '';
          this.formDatas.maintenancedepid_maintenanceperson1 = deptInfo.maintenanceperson1 || '';
          this.formDatas.maintenancedepid_maintenancephone1 = deptInfo.maintenancephone1 || '';
          this.formDatas.maintenancedepid_maintenanceperson2 = deptInfo.maintenanceperson2 || '';
          this.formDatas.maintenancedepid_maintenancephone2 = deptInfo.maintenancephone2 || '';
          this.formDatas.maintenancedepid_areamanager = deptInfo.areamanager || '';
          this.formDatas.maintenancedepid_areamanagerphone = deptInfo.areamanagerphone || '';
          this.formDatas.maintenancedepid_depmanager = deptInfo.depmanager || '';
          this.formDatas.maintenancedepid_depmanagerphone = deptInfo.depmanagerphone || '';
          this.formDatas.maintenancedepid_mailcode = deptInfo.mailcode || '';
        }
      }else {
        this.formDatas.maintenancedepid = '';
        this.maintenancegroupidOptions = [];
      }
    },
    examinedepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.examinedepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('examinedepid')
      }else {
        this.formDatas.examinedepid = '';
      }
    },
    rescuedepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.rescuedepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('rescuedepid')
      }else {
        this.formDatas.rescuedepid = '';
      }
    },
    testdepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.testdepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('testdepid')
      }else {
        this.formDatas.testdepid = '';
      }
    },
    installtimeMoment(val) {
      if(val) {
        this.formDatas.installtime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('installtime')
      }else {
        this.formDatas.installtime = '';
      }
    },
    usetimeMoment(val) {
      if(val) {
        this.formDatas.usetime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('usetime')
      }else {
        this.formDatas.usetime = '';
      }
    },
    bigrepairtimeMoment(val) {
      if(val) {
        this.formDatas.bigrepairtime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('bigrepairtime')
      }else {
        this.formDatas.bigrepairtime = '';
      }
    },
    insuranceexpiretimeMoment(val) {
      if(val) {
        this.formDatas.insuranceexpiretime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('insuranceexpiretime')
      }else {
        this.formDatas.insuranceexpiretime = '';
      }
    },
    constructiontimeMoment(val) {
      if(val) {
        this.formDatas.constructiontime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('constructiontime')
      }else {
        this.formDatas.constructiontime = '';
      }
    },
    checktimeMoment(val) {
      if(val) {
        this.formDatas.checktime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('checktime')
      }else {
        this.formDatas.checktime = '';
      }
    },
    producttimeMoment(val) {
      if(val) {
        this.formDatas.producttime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('producttime')
      }else {
        this.formDatas.producttime = '';
      }
    },
    maintenancecontacttimeMoment(val) {
      if(val) {
        this.formDatas.maintenancecontacttime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('maintenancecontacttime')
      }else {
        this.formDatas.maintenancecontacttime = '';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
    this.initPlaceidOptions();
    this.initDevicetypeidOptions();
    this.initBrandidOptions();
    this.initDragtypeOptions();
    this.initEstatemodelidOptions();
    this.initMaintenancetypeOptions();
    // this.initMaintenancegroupidOptions();
    this.initManufactureridOptions();
    this.getTerminaltypes();
  },
  methods: {
    handleChange(value) {
      //console.log(`selected ${value}`);
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    filter(inputValue, path) {
      return path.some(option => option.userdepname.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    areaFilter(inputValue, path) {
      return path.some(option => option.areaname.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    change1(msg) {
      this.formDatas.brandid = msg[0].liftpropertyid
      this.formDatas.brandname = msg[0].value
    },
    change2(msg) {
      this.formDatas.manufacturerid = msg[0].liftpropertyid
      this.formDatas.manufacturername = msg[0].value
    },
    getTerminaltypes() {
      getTerminaltypeListByCondition({}).then(res => {
        if (res && res.returncode == '0') {
          this.terminaltypes = res.item.map(item => {
            return {
              value: item.terminaltypeid,
              text: item.terminaltypename,
            }
          });
          this.showDeviceTable = true;
        }
      })
    },
    initPlaceidOptions() {
      let params = {
        liftpropertytype: '4',
      }
      getLiftListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          this.placeidOptions = res.item;
        }
      })
    },
    initDevicetypeidOptions() {
      let params = {
        liftpropertytype: '1',
      }
      getLiftListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          this.devicetypeidOptions = res.item;
        }
      })
    },
    initBrandidOptions() {
      let params = {
        liftpropertytype: '3',
      }
      getLiftListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          this.brandidOptions = res.item;
          this.dataSource = res.item
        }
      })
    },
    initDragtypeOptions() {
      let params = {
        dictype: '13',
      }
      getDictByDicType(params).then(res => {
        if (res && res.returncode == '0') {
          this.dragtypeOptions = res.item;
        }
      })
    },
    initEstatemodelidOptions() {
      let params = {
        liftpropertytype: '5',
      }
      getLiftListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          this.estatemodelidOptions = res.item;
        }
      })
    },
    initMaintenancetypeOptions() {
      let params = {
        dictype: '14',
      }
      getDictByDicType(params).then(res => {
        if (res && res.returncode == '0') {
          this.maintenancetypeOptions = res.item;
        }
      })
    },
    initMaintenancegroupidOptions() {
      let params = {
        maintenancedepid: this.formDatas.maintenancedepid,
      }
      // getMentenacegroupListByCondition(params).then(res => {
      //   if (res && res.returncode == '0') {
      //     this.maintenancegroupidOptions = res.item;
      //   }
      // })
    },
    initManufactureridOptions() {
      let params = {
        liftpropertytype: '6',
      }
      getLiftListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          this.manufactureridOptions = res.item;
        }
      })
    },
    initDetail() {
      if (this.detailData && this.detailData.liftid && (this.showType == 'edit' || this.showType == 'detail' || this.showType == 'check')) {
        this.$nextTick(() => {
          let params = {
            liftid: this.detailData.liftid
          }
          getLiftinfoById(params).then(res => {
            if (res && res.returncode == '0') {
              for (let key in this.formDatas) {
                if (res.item[key] || res.item[key] === 0) {
                  this.formDatas[key] = res.item[key];
                }
              }
              this.formDatas.userdepname=res.item.userdep
              this.formDatas.maintenancedep=res.item.maintenancedep
              this.formDatas.manufacturerdep=res.item.manufacturerdep
              this.formDatas.rescuedep=res.item.rescuedep
              this.formDatas.testdep=res.item.testdep
              this.formDatas.examinedep=res.item.examinedep
              // 特别数据回显
              if (res.item.registertime) {
                this.registertimeMoment = moment(res.item.registertime, 'YYYYMMDD')
              }
              if (res.item.registeruptime) {
                this.registeruptimeMoment = moment(res.item.registeruptime, 'YYYYMMDD')
              }
              if (res.item.devicemodelid) {
                this.deviceModelObj = {
                  liftpropertyid: res.item.devicemodelid,
                  value: res.item.devicemodelname || 'testdevicemodel',
                }
              }
              if (res.item.communityid) {
                this.communityidObj = {
                  liftpropertyid: res.item.communityid,
                  value: res.item.communityname || 'testcommunity',
                }
              }
              if (res.item.areacode) {
                this.initAreaOptionsByAreacode(res.item.areacode)
              }
              if (res.item.registerdep) {
                this.initDeptOptionsByUserdepid(res.item.registerdep, 'registerdepCascaderSelected', this.userInfo.usertype != '2')
              }
              if (res.item.adminuserdepid) {
                this.initDeptOptionsByUserdepid(res.item.adminuserdepid, 'adminuserdepidCascaderSelected', this.userInfo.usertype != '2')
              }
              if (res.item.devicelicstarttime && res.item.devicelicendtime) {
                this.devicelictimeRange = [moment(res.item.devicelicstarttime, 'YYYYMMDD'), moment(res.item.devicelicendtime, 'YYYYMMDD')]
              }
              if (res.item.userdepid) {
                this.initDeptOptionsByUserdepid(res.item.userdepid, 'userdepidCascaderSelected', this.userInfo.usertype != '1')
                if (res.item.userdepLists) {
                  this.formDatas.userdepid_depnum = res.item.userdepLists.depnum || '';
                  this.formDatas.userdepid_businessnum = res.item.userdepLists.businessnum || '';
                  this.formDatas.userdepid_address = res.item.userdepLists.address || '';
                  this.formDatas.userdepid_liftpropertyname = res.item.userdepLists.liftpropertyname || '';
                  this.formDatas.userdepid_mailcode = res.item.userdepLists.mailcode || '';
                  this.formDatas.userdepid_safeperson = res.item.userdepLists.safeperson || '';
                  this.formDatas.userdepid_safephone = res.item.userdepLists.safephone || '';
                  this.formDatas.userdepid_liftpropertyid = res.item.userdepLists.liftpropertyid || '';
                }
              }
              if (res.item.maintenancedepid) {
                this.initDeptOptionsByUserdepid(res.item.maintenancedepid, 'maintenancedepidCascaderSelected', this.userInfo.usertype != '3')
                // 维保单位信息
                if (res.item.maintenancedepLists) {
                  this.formDatas.maintenancedepid_depnum = res.item.maintenancedepLists.depnum || '';
                  this.formDatas.maintenancedepid_certificatenum = res.item.maintenancedepLists.certificatenum || '';
                  this.formDatas.maintenancedepid_businessnum = res.item.maintenancedepLists.businessnum || '';
                  this.formDatas.maintenancedepid_licensenum = res.item.maintenancedepLists.licensenum || '';
                  this.formDatas.maintenancedepid_address = res.item.maintenancedepLists.address || '';
                  this.formDatas.maintenancedepid_rescuepreson = res.item.maintenancedepLists.rescuepreson || '';
                  this.formDatas.maintenancedepid_rescuephone = res.item.maintenancedepLists.rescuephone || '';
                  this.formDatas.maintenancedepid_maintenanceperson1 = res.item.maintenancedepLists.maintenanceperson1 || '';
                  this.formDatas.maintenancedepid_maintenancephone1 = res.item.maintenancedepLists.maintenancephone1 || '';
                  this.formDatas.maintenancedepid_maintenanceperson2 = res.item.maintenancedepLists.maintenanceperson2 || '';
                  this.formDatas.maintenancedepid_maintenancephone2 = res.item.maintenancedepLists.maintenancephone2 || '';
                  this.formDatas.maintenancedepid_areamanager = res.item.maintenancedepLists.areamanager || '';
                  this.formDatas.maintenancedepid_areamanagerphone = res.item.maintenancedepLists.areamanagerphone || '';
                  this.formDatas.maintenancedepid_depmanager = res.item.maintenancedepLists.depmanager || '';
                  this.formDatas.maintenancedepid_depmanagerphone = res.item.maintenancedepLists.depmanagerphone || '';
                  this.formDatas.maintenancedepid_mailcode = res.item.maintenancedepLists.mailcode || '';
                }
              }
              // 检验信息
              if (res.item.examineLists) {
                this.examineLists = res.item.examineLists;
                this.formDatas.examineperson = res.item.examineLists.examineperson;
                this.formDatas.examinetime = res.item.examineLists.examinetime;
                if (res.item.examineLists.examinetime) {
                  this.examinetimeMoment = moment(res.item.examineLists.examinetime, 'YYYYMMDD')
                }
                this.formDatas.nextexaminetime = res.item.examineLists.nextexaminetime;
                if (res.item.examineLists.nextexaminetime) {
                  this.nextexaminetimeMoment = moment(res.item.examineLists.nextexaminetime, 'YYYYMMDD')
                }
                this.formDatas.result = '' + res.item.examineLists.result;
                this.formDatas.resultdesc = res.item.examineLists.resultdesc;
                this.formDatas.examinenum = res.item.examineLists.examinenum;
              } else {
                this.examineLists = {};
              }
              if (res.item.testLists) {
                this.testLists = res.item.testLists;
                this.formDatas.testexamineperson = res.item.testLists.examineperson;
                this.formDatas.testexaminetime = res.item.testLists.examinetime;
                if (res.item.testLists.examinetime) {
                  this.testexaminetimeMoment = moment(res.item.testLists.examinetime, 'YYYYMMDD')
                }
                this.formDatas.testnextexaminetime = res.item.testLists.nextexaminetime;
                if (res.item.testLists.nextexaminetime) {
                  this.testnextexaminetimeMoment = moment(res.item.testLists.nextexaminetime, 'YYYYMMDD')
                }
                this.formDatas.testresult = '' + res.item.testLists.result;
                this.formDatas.testresultdesc = res.item.testLists.resultdesc;
                this.formDatas.testexaminenum = res.item.testLists.examinenum;
              } else {
                this.testLists = {};
              }
              if (res.item.examinedepid) {
                this.initDeptOptionsByUserdepid(res.item.examinedepid, 'examinedepidCascaderSelected', this.userInfo.usertype != '4')
              }
              if (res.item.rescuedepid) {
                this.initDeptOptionsByUserdepid(res.item.rescuedepid, 'rescuedepidCascaderSelected', this.userInfo.usertype != '6')
              }
              if (res.item.testdepid) {
                this.initDeptOptionsByUserdepid(res.item.testdepid, 'testdepidCascaderSelected', this.userInfo.usertype != '5')
              }
              if (res.item.installtime) {
                this.installtimeMoment = moment(res.item.installtime, 'YYYYMMDD')
              }
              if (res.item.usetime) {
                this.usetimeMoment = moment(res.item.usetime, 'YYYYMMDD')
              }
              if (res.item.bigrepairtime) {
                this.bigrepairtimeMoment = moment(res.item.bigrepairtime, 'YYYYMMDD')
              }
              if (res.item.insuranceexpiretime) {
                this.insuranceexpiretimeMoment = moment(res.item.insuranceexpiretime, 'YYYYMMDD')
              }
              if (res.item.constructiontime) {
                this.constructiontimeMoment = moment(res.item.constructiontime, 'YYYYMMDD')
              }
              if (res.item.checktime) {
                this.checktimeMoment = moment(res.item.checktime, 'YYYYMMDD')
              }
              if (res.item.producttime) {
                this.producttimeMoment = moment(res.item.producttime, 'YYYYMMDD')
              }
              if (res.item.maintenancecontacttime) {
                this.maintenancecontacttimeMoment = moment(res.item.maintenancecontacttime, 'YYYYMMDD')
              }
              if (res.item.userflagurl) {
                let nameIndex = res.item.userflagurl.lastIndexOf('/');
                if (nameIndex < 0) {
                  nameIndex = res.item.userflagurl.lastIndexOf('\\');
                }
                let fileName = res.item.userflagurl.substr(nameIndex + 1);
                this.userflagurlList = [{
                  uid: 'userflagurluid',
                  name: fileName,
                  status: 'done',
                  url: res.item.userflagurl,
                }];
              }
              if (res.item.userloginurl) {
                let nameIndex = res.item.userloginurl.lastIndexOf('/');
                if (nameIndex < 0) {
                  nameIndex = res.item.userloginurl.lastIndexOf('\\');
                }
                let fileName = res.item.userloginurl.substr(nameIndex + 1);
                this.userloginurlList = [{
                  uid: 'userloginurluid',
                  name: fileName,
                  status: 'done',
                  url: res.item.userloginurl,
                }];
              }
              if (res.item.maintenancecontacturl) {
                let nameIndex = res.item.maintenancecontacturl.lastIndexOf('/');
                if (nameIndex < 0) {
                  nameIndex = res.item.maintenancecontacturl.lastIndexOf('\\');
                }
                let fileName = res.item.maintenancecontacturl.substr(nameIndex + 1);
                this.maintenancecontacturlList = [{
                  uid: 'maintenancecontacturluid',
                  name: fileName,
                  status: 'done',
                  url: res.item.maintenancecontacturl,
                }];
              }
              if (res.item.deviceid == 0) {
                res.item.deviceid = '';
                this.formDatas.deviceid = '';
              }
              if (res.item.monitorinfos && res.item.monitorinfos.length) {
                this.monitorList = res.item.monitorinfos.map(item => {
                  item.monitorid = '' + item.monitorid;
                  return item;
                });
              }
              // if(res.item.deviceid) {
              //   this.deviceList = res.item.deviceid.split('|').map(item => {return {deviceinfoid:item}});
              // }
              if (res.item.deviceinfos && res.item.deviceinfos.length) {
                this.deviceList = res.item.deviceinfos.map(item => {
                  item.deviceinfoid = '' + item.deviceinfoid;
                  return item;
                });
              }
            }
          })
        })
      }
    },
    resetDatas() {
      this.formDatas.liftid = '';
      this.registertimeMoment = null;
      this.registeruptimeMoment = null;
      this.examinetimeMoment = null;
      this.nextexaminetimeMoment = null;
      this.testexaminetimeMoment = null;
      this.testnextexaminetimeMoment = null;
      this.areaCascaderSelected = [];
      this.deviceModelObj = null;
      this.communityidObj = null;
      this.devicelictimeRange = null;
      this.userdepidCascaderSelected = [];
      this.registerdepCascaderSelected = [];
      this.adminuserdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.examinedepidCascaderSelected = [];
      this.rescuedepidCascaderSelected = [];
      this.testdepidCascaderSelected = [];
      this.installtimeMoment = null;
      this.usetimeMoment = null;
      this.bigrepairtimeMoment = null;
      this.insuranceexpiretimeMoment = null;
      this.constructiontimeMoment = null;
      this.checktimeMoment = null;
      this.producttimeMoment = null;
      this.maintenancecontacttimeMoment = null;
      this.userflagurlList = [];
      this.userloginurlList = [];
      this.maintenancecontacturlList = [];
      this.deviceList = null;
      this.monitorList = null;
      if (this.$refs.modalForm) {
        this.$refs.modalForm.clearValidate();
      }
      for (let key in this.formDatas) {
        if (key == 'checkstatus') {
          this.formDatas[key] = '0';
        } else {
          this.formDatas[key] = '';
        }
      }
    },
    confirm(checkstatus) {
      if (this.formDatas.deviceid == null || this.formDatas.deviceid == '') {
        this.formDatas.linkiot = 0
      } else {
        this.formDatas.linkiot = 1
      }
      // success fail
      if (this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            let params = {
              ...this.formDatas,
              deviceid: this.formDatas.deviceid == '' ? '0' : this.formDatas.deviceid,
              monitorid: this.formDatas.monitorid == '' ? '0' : this.formDatas.monitorid,
              checkstatus,
            };
            if (this.showType == 'add'&&this.formDatas.brandid!='') {
              this.showLoading();
              addLiftinfo(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.examineLift(res.liftid);
                  this.testLift(res.liftid);
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.formDatas.brandid!=''){
              this.showLoading();
              modifyLiftinfo(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  // this.examineLift();
                  // this.testLift();
                  // this.maintenanceLift();
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else if (this.showType == 'check') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.showLoading();
            let params = {
              ...this.formDatas,
              checkstatus: '1',
            };
            modifyLiftinfo(params).then(res => {
              if (res && res.returncode == '0') {
                if (checkstatus == '3') {
                  this.hideLoading();
                  this.showApproveFailModal = true;
                } else {
                  // this.maintenanceLift();
                  let checkParams = {
                    liftids: this.formDatas.liftid,
                    approvestatus: checkstatus,//2-审核通过，3-审核不通过
                    approvedesc: '',
                  }
                  approveLift(checkParams).then(res => {
                    this.hideLoading();
                    if (res && res.returncode == '0') {
                      this.$message.success('操作成功');
                      this.$emit('get-operation-result', 'success', '操作成功');
                      this.modalVisible = false;
                    } else {
                      this.$message.error(res.errormsg || '操作失败');
                    }
                  })
                }
              } else {
                this.hideLoading();
                this.$message.error(res.errormsg || '修改失败');
              }
            })
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    maintenanceLift() {
      let params = {
        userdepid: this.formDatas.maintenancedepid,
        // userdepname: this.formDatas.maintenancedepname,
        levelid: this.formDatas.maintenancedeplevelid,
        deptype: this.formDatas.maintenancedeptype,
        maintenanceperson1: this.formDatas.maintenancedepid_maintenanceperson1,
        maintenancephone1: this.formDatas.maintenancedepid_maintenancephone1,
        maintenanceperson2: this.formDatas.maintenancedepid_maintenanceperson2,
        maintenancephone2: this.formDatas.maintenancedepid_maintenancephone2,
      }
      // modifyUserdep(params)
    },
    examineLift(liftid) {
      if (this.formDatas.examinedepid != this.examineLists.examinedepid || this.formDatas.examineperson != this.examineLists.examineperson || this.formDatas.examinetime != this.examineLists.examinetime || this.formDatas.nextexaminetime != this.examineLists.nextexaminetime || this.formDatas.result != this.examineLists.result || this.formDatas.resultdesc != this.examineLists.resultdesc || this.formDatas.examinenum != this.examineLists.examinenum) {
        let examineParams = {
          liftid: liftid || this.formDatas.liftid,
          examinedepid: this.formDatas.examinedepid,
          examineperson: this.formDatas.examineperson,
          examinetime: this.formDatas.examinetime,
          nextexaminetime: this.formDatas.nextexaminetime,
          result: this.formDatas.result,
          resultdesc: this.formDatas.resultdesc,
          examinenum: this.formDatas.examinenum,
          examinetype: '4',//检验：4 检测：5
        }
      }
    },
    testLift(liftid) {
      if (this.formDatas.testdepid != this.testLists.examinedepid || this.formDatas.testexamineperson != this.testLists.examineperson || this.formDatas.testexaminetime != this.testLists.examinetime || this.formDatas.testnextexaminetime != this.testLists.nextexaminetime || this.formDatas.testresult != this.testLists.result || this.formDatas.testresultdesc != this.testLists.resultdesc || this.formDatas.testexaminenum != this.examineLists.examinenum) {
        let testParams = {
          liftid: liftid || this.formDatas.liftid,
          examinedepid: this.formDatas.testdepid,
          examineperson: this.formDatas.testexamineperson,
          examinetime: this.formDatas.testexaminetime,
          nextexaminetime: this.formDatas.testnextexaminetime,
          result: this.formDatas.testresult,
          resultdesc: this.formDatas.testresultdesc,
          examinenum: this.formDatas.testexaminenum,
          examinetype: '5',//检验：4 检测：5
        };
      }
    },
    approveResult() {
      this.$emit('get-operation-result', 'success', '操作成功');
      this.modalVisible = false;
    },
    getCoordinate(coordinate) {
      this.formDatas.coordinatex = coordinate[0];
      this.formDatas.coordinatey = coordinate[1];
    },
    getDevicemodelSelectRows(rows) {
      this.deviceModelObj = rows[0]
    },
    getcommunityidmodelSelectRows(rows) {
      this.communityidObj = rows[0]
    },
    getDeviceList(rows) {
      this.deviceList = rows;
      if(this.deviceList.length>0){
        this.getParamsConfig(this.deviceList[0].devicenum)
      }
    },
    getMonitorList(rows) {
      this.monitorList = rows;
    },
    userflagurlUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.formDatas.userflagurl = file.response.urlPath;
        }
        return file;
      });

      this.userflagurlList = fileList;
    },
    userloginurlUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.formDatas.userloginurl = file.response.urlPath;
        }
        return file;
      });

      this.userloginurlList = fileList;
    },
    maintenancecontacturlUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.formDatas.maintenancecontacturl = file.response.urlPath;
        }
        return file;
      });

      this.maintenancecontacturlList = fileList;
    },
    getParamsConfig(devicenum) {
      this.paramsList = [];
      let params = {
        devicenum: devicenum,
        data: [
          {
            modeltype: "",
            modelnum: "",
            params: {
              methord: 'GET',
              url: '/linphone',
            }
          }
        ]
      };
      // console.log(params)
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.formDatas.rescueperson = res.item[0].params.body.rescuer_name
          this.formDatas.rescuephone = res.item[0].params.body.numbers
          let params = {
            liftid: this.detailData.liftid,
          }
          getLiftinfoById(params).then(res => {
            if (res && res.returncode == '0') {
              let param = res.item
              param.rescueperson = this.formDatas.rescuepreson,
              param.rescuephone = this.formDatas.rescuephone,
              modifyLiftinfo(param)
            }
          }).catch(err => {
            this.hideLoading();
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>